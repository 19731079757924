.contact-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 20px;
    .submit-button {
      background-color: transparent;
      border: 1px solid white;
      outline: none;
      height: 21px;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        ul {
          transform: translateY(-18px);
        }
      }
      ul {
        transition: transform 0.3s;
        list-style: none;
        padding: 0px;
        margin: 0px;
        &.sending {
          transform: translateY(-39px);
        }
        li {
          margin-bottom: 5px;
        }
      }
    }
    p {
      margin: 5px;
    }
    .input-box {
      background: transparent;
      outline: none;
      border: none;
      color: darkgray;
      font-weight: 400;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      letter-spacing: 3px;
      border-bottom: 1px solid white;
      margin-bottom: 10px;
      &.error {
        border-bottom: 1px solid red;
      }
    }
    textarea {
      border: 1px solid white;
      outline: none;
      background: transparent;
      margin-bottom: 10px;
      resize: none;
      height: 60px;
      color: darkgray;
      font-weight: 400;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      letter-spacing: 3px;
      &.error {
        border: 1px solid red;
      }
      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: darkgray;
      }
    }
  }
  .contact-details {
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      list-style: none;
      padding: 0px;
      i {
        margin-right: 10px;
      }
      li {
        margin-bottom: 10px;
      }
    }
  }
}
