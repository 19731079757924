@font-face {
  font-family: 'LaBelleAurore';
  src: local('LaBelleAurore'),
    url(./fonts/LaBelleAurore-Regular.ttf) format('truetype');
}

.page {
  position: relative;
  display: flex;
  padding-left: 60px;
  min-width: calc(90vw - 60px);
  min-height: 100%;
  overflow: hidden;
  @media (max-width: 960px) {
    padding-left: 0px;
    min-width: 90vw;
    padding-top: 100px;
    min-height: calc(100% - 100px);
  }
  .content-zone {
    margin: 10px 0px 0px 5vw;
    h1 {
      color: white;
      font-weight: 700;
      letter-spacing: -2px;
      text-transform: lowercase;
      margin: 0px;
      position: relative;
      &::before {
        content: '<h1>';
        font-family: 'LaBelleAurore';
        color: #515152;
        font-size: 20px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        font-weight: 400;
      }
      &::after {
        content: '<h1/>';
        font-family: 'LaBelleAurore';
        color: #515152;
        font-size: 20px;
        position: absolute;
        margin-bottom: -10px;
        left: -10px;
        bottom: -10px;
        font-weight: 400;
      }
    }
  }
  .content-zone > div {
    color: darkgray;
    margin-top: 20px;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 3px;
  }
  .tags {
    font-size: 30px;
    font-family: 'LaBelleAurore';
    user-select: none;
    font-weight: 400;
    margin-left: -30px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    p {
      font-family: monospace;
      text-align: center;
      font-size: 15px;
      color: darkgray;
      margin: auto;
      .love-symbol {
        color: #ec795e;
      }
    }
  }
}

h1 {
  font-size: calc(20px + 2vw);
  transition: all 0.5s;
}

h2 {
  font-size: calc(10px + 0.5vw);
}

p {
  font-size: calc(10px + 0.3vw);
}
