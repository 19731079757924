.header-home {
  color: white;
  font-family: 'Special Elite', cursive;
  text-align: center;
  font-size: 4rem;
  user-select: none;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  animation: blinkTextCursor 600ms infinite normal;
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }

  to {
    border-right-color: rgba(255, 255, 255, 0);
  }
}
