body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #444;
  background: #1d1d1d;
  overflow: auto;
  overflow-x: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html, body, #root {
  width: 100%;
  height: 100%;
}