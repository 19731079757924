.nav_bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  min-height: 500px;

  .logo {
    margin: 12px 0px 0px 8px;
    svg {
      animation: fill 0.5s ease forwards 1s;
    }

    .squiggle {
      stroke-dasharray: 225px;
      stroke-dashoffset: 225px;
      animation: line-anim 2s ease forwards 0.2s;
    }
    @keyframes line-anim {
      to {
        stroke-dashoffset: 0px;
      }
    }

    @keyframes fill {
      from {
        fill: transparent;
      }
      to {
        fill: white;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    @media (max-width: 960px) {
      display: flex;
      width: 42%;
      height: 60px;
      justify-content: space-evenly;
      top: 0px;
      left: 50%;
      transition: all 0.3s ease-out;
    }

    @media (max-width: 530px) {
      width: 100%;
      position: fixed;
      left: 100%;
      opacity: 0;
      height: 60px;
      top: 60px;
      margin-top: 0;
      background: #181818;
      -webkit-transition: all 0.3s ease-out;
      &.show {
        left: 0;
        opacity: 1;
      }
    }

    a {
      font-size: 20px;
      color: #909096;
      margin-bottom: 15px;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;
      i {
        transition: all 0.3s ease-out;
      }
      &::after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }
      &:first-child:after {
        content: 'HOME';
        @media (max-width: 960px) {
          left: -6px;
        }
      }
      &:nth-child(2):after {
        content: 'ABOUT';
        @media (max-width: 960px) {
          left: -12px;
        }
      }
      &:nth-child(3):after {
        content: 'SKILLS';
        @media (max-width: 960px) {
          left: -10px;
        }
      }
      &:nth-child(4):after {
        content: 'WORK';
        @media (max-width: 960px) {
          left: -6px;
        }
      }
      &:nth-child(5):after {
        content: 'CONTACT';
        @media (max-width: 960px) {
          left: -12px;
        }
      }
      &.active,
      &:hover {
        color: white;
        &::after {
          opacity: 1;
        }
        i {
          opacity: 0;
        }
      }
    }
  }

  ul {
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;
    padding-bottom: 20px;
    @media (max-width: 960px) {
      align-items: center;
      display: flex;
      min-height: 60px;
      width: 50px;
      justify-content: space-evenly;
    }
    @media (max-width: 530px) {
      display: none;
    }
    li a {
      padding: 7px 0;
      display: block;
      font-size: 15px;
      line-height: 16px;
      color: #909096;
      &:hover {
        color: white;
      }
    }
  }
  @media (max-width: 960px) {
    flex-direction: row;
    width: 100%;
    height: 60px;
    min-height: 0;
    @media (max-width: 960px) {
      nav a {
        line-height: 60px;
        height: 60px;
      }
    }
  }
}
