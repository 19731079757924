.work-container {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  margin: 20px 20px 40px 0px;
  &.reverse {
    flex-direction: row-reverse;
  }
  .button-center {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    a {
      display: inline;
      padding: 5px 15px;
      border-radius: 3px;
      cursor: pointer;
      background-color: #909096;
      color: black;
      margin: 10px;
    }
  }
  .browser {
    display: block;
    border-radius: 10px;
    border-bottom: 10px solid rgb(144, 144, 150);
    max-width: 300px;
    @media (max-width: 760px) {
      width: 100%;
      max-width: unset;
    }
    .browser-header {
      background: rgb(144, 144, 150);
      padding: 10px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      display: flex;
      i {
        border-radius: 10px;
        margin: 0 3px;
        width: 10px;
        height: 10px;
        &:nth-child(1) {
          background-color: #d56363;
        }
        &:nth-child(2) {
          background-color: #ffd248;
        }
        &:nth-child(3) {
          background-color: #9dc75a;
        }
        &:nth-child(4) {
          flex: 1;
          background-color: #e8f4f7;
        }
      }
    }
    .browser-body {
      height: auto;
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .work-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 10px;
    width: 50%;
    @media (max-width: 760px) {
      width: auto;
    }
    h2 {
      color: white;
    }
    .tech-tags {
      border-top: 1px dashed darkgray;
      span {
        display: inline-block;
        color: darkgrey;
        font-size: 11px;
        padding: 4px 8px;
        margin: 4px 2px;
      }
    }
  }
}
