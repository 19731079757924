.skill-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    @media (max-width: 960px) {
      width: auto;
      margin-bottom: 20px;
    }
  }

  .skill-chart {
    width: calc(50% - 10px);
    @media (max-width: 960px) {
      width: 100%;
    }
    .chart__bar {
      display: flex;
      justify-content: space-between;
      height: 20px;
      margin-bottom: 10px;
      background: linear-gradient(to left, #4cb8c4, #3cd3ad);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      opacity: 0;
      width: 0%;
      overflow: hidden;
      list-style: none;
      .chart__label {
        padding-left: 5px;
        line-height: 20px;
        color: white;

        &.header {
          border: 1px solid #4cb8c4;
          background: #1d1d1d;
          padding-right: 5px;
        }
      }
    }
  }
}
