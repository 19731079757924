$background: #3d566e;
$color: #ecf0f1;

$height-icon: 20px;
$width-line: 30px;
$height-line: 3px;

$transition-time: 0.4s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;

.hamburger {
  width: $width-line;
  height: $height-icon;
  position: relative;
  display: none;
  margin: 20px;

  @media (max-width: 530px) {
    display: block;
  }

  .line {
    display: block;
    background: $color;
    width: $width-line;
    height: $height-line;
    position: absolute;
    left: 0;
    border-radius: ($height-line / 2);
    transition: all $transition-time;
    -webkit-transition: all $transition-time;
    -moz-transition: all $transition-time;

    &.line1 {
      top: 0;
    }
    &.line2 {
      top: 50%;
    }
    &.line3 {
      top: 100%;
    }
  }
  &:hover {
    .line1 {
      transform: translateY($height-line / 2 * -1);
      -webkit-transform: translateY($height-line / 2 * -1);
      -moz-transform: translateY($height-line / 2 * -1);
    }
    .line3 {
      transform: translateY($height-line / 2);
      -webkit-transform: translateY($height-line / 2);
      -moz-transform: translateY($height-line / 2);
    }
  }
  &.active {
    .line1 {
      transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
      -webkit-transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
      -moz-transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
      -webkit-transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
      -moz-transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
    }
  }
}
